import { Typography, Button } from "@material-tailwind/react";
import { RocketLaunchIcon } from "@heroicons/react/24/solid";
import { Footer } from "@/widgets/layout";
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
export function DuckArticle() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="relative block h-[50vh]">
        <div className="bg-duck-background absolute top-0 h-full w-full bg-[url('/img/about_duck.webp')] bg-cover bg-center scale-105" />
        <div className="absolute top-0 h-full w-full bg-yellow-500/60 bg-cover bg-center" />
      </section>

      <section className="relative bg-white py-16">
        <div className="container mx-auto px-4">
          <div className="mb-6 text-center">
            <Typography variant="h2" color="blue-gray" className="font-bold">
              Hvorfor du trenger en 3D-Printet and i livet litt
            </Typography>
            <Typography variant="lead" color="gray" className="mt-4">
              En guide til hvordan en enkel, gul 3D-printet and kan forbedre
              hverdagen din på måter du aldri hadde forventet.
            </Typography>
          </div>

          <div className="text-left space-y-8">
            <Typography variant="h4" color="blue-gray" className="font-semibold">
              En venn for livet
            </Typography>
            <Typography variant="paragraph" color="blue-gray" className="font-normal">
              Våre 3D-printede ender er mer enn bare pynt. De er trofaste følgesvenner som 
              kan bringe et snev av glede og humor til ethvert rom. Enten du plasserer Sir 
              Quackalot på kontorpulten din eller tar QuackyMcFly med på reiser, vil de 
              alltid gi deg et smil om munnen.
            </Typography>

            <Typography variant="h4" color="blue-gray" className="font-semibold">
              Et miljøvennlig valg
            </Typography>
            <Typography variant="paragraph" color="blue-gray" className="font-normal">
              Våre ender er laget av PLA, et miljøvennlig materiale som er både biologisk 
              nedbrytbart og bærekraftig. Det betyr at du ikke bare får et stilig designobjekt, 
              men også gjør et grønt valg for planeten. 
            </Typography>

            <Typography variant="h4" color="blue-gray" className="font-semibold">
              Allsidighet i bruk
            </Typography>
            <Typography variant="paragraph" color="blue-gray" className="font-normal">
              Fra pynt til samtalestarter, våre 3D-printede ender har uendelig mange bruksområder. 
              Du kan bruke dem som en quirky detalj på kontoret, en artig gave til en venn, 
              eller til og med som en følgesvenn på reiser. Vil du være den neste til å ta dem med på nye eventyr?
            </Typography>

            <Typography variant="h4" color="blue-gray" className="font-semibold">
              Start samlingen din nå
            </Typography>
            <Typography variant="paragraph" color="blue-gray" className="font-normal">
              Begynn å samle dine egne 3D-printede ender i dag. Fra Sir Quackalot, den 
              sofistikerte gentlemannen med sin elegante flosshatt, til QuackyMcFly, den 
              sprø eventyreren med sans for det kule – det finnes en and for alle. Det 
              eneste spørsmålet er: hvilken vil du ha først?
            </Typography>

            <Link to="/products">
              <Button className="bg-yellow-500 mt-6">
                Produkter
              </Button>
           </Link>

          </div>
        </div>
      </section>

      <div className="bg-white">
        <Footer />
      </div>
    </>
  );
}

export default DuckArticle;
