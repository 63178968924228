import { Typography, Card, CardBody } from "@material-tailwind/react";
import { Footer } from "@/widgets/layout";

export function AboutUs() {
  return (
    <>
      {/* Bakgrunnsseksjon med bilde */}
      <section className="relative block h-[50vh] md:h-[70vh]">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('/img/showcase_duck.webp')] bg-cover bg-center md:bg-right bg-no-repeat" />
        <div className="absolute top-0 h-full w-full bg-black/60 z-0" />
      </section>

      {/* Om oss seksjon */}
      <section className="relative bg-white py-16">
        <div className="container mx-auto max-w-4xl">

          <Typography variant="h3" color="blue-gray" className="text-center mb-12">
            Om QuackShop og PolarCode Solutions
          </Typography>

          {/* Card 1 */}
          <Card className="mb-8 shadow-lg">
            <CardBody>
              <Typography color="blue-gray" className="mb-4 text-lg">
                PolarCode Solutions har utviklet denne nettbutikken som en demonstrasjon på hva vi kan oppnå innen netthandel og moderne webutvikling. Vårt mål er å vise frem vår kompetanse innen å bygge komplette, funksjonelle løsninger som er både brukervennlige og teknologisk avanserte.
              </Typography>
            </CardBody>
          </Card>

          {/* Card 2 */}
          <Card className="mb-8 shadow-lg">
            <CardBody>
              <Typography color="blue-gray" className="mb-4 text-lg">
                Denne nettbutikken er utviklet for å gi et praktisk eksempel på hvordan vi kan levere skreddersydde løsninger tilpasset ulike behov. Uavhengig av prosjektets omfang eller spesifikke krav, har vi erfaringen som kreves for å bygge nettbutikker som kombinerer stil, ytelse og funksjonalitet.
              </Typography>
            </CardBody>
          </Card>

          {/* Card 3 */}
          <Card className="mb-8 shadow-lg">
            <CardBody>
              <Typography color="blue-gray" className="mb-4 text-lg">
                QuackShop er ikke bare et konsept, men et fullt fungerende nettsted med alle nødvendige funksjoner for netthandel, inkludert handlekurv, betalingsløsninger og ordrehåndtering. Det viser at vi ikke bare skaper noe som ser bra ut, men også at vi utvikler løsninger som virkelig fungerer i praksis.
              </Typography>
            </CardBody>
          </Card>

          {/* Card 4 */}
          <Card className="mb-8 shadow-lg">
            <CardBody>
              <Typography color="blue-gray" className="mb-4 text-lg">
                Hos PolarCode Solutions setter vi brukervennlighet og fleksibilitet i høysetet. Vi jobber tett med våre kunder for å sikre at deres nettsider møter deres unike behov, og vi kan tilpasse løsningen for enhver bransje eller nisje. Vi er stolte av vår evne til å tilby dynamiske og skalerbare løsninger som kan vokse sammen med din virksomhet.
              </Typography>
            </CardBody>
          </Card>

          {/* Card 5 */}
          <Card className="mb-8 shadow-lg">
            <CardBody>
              <Typography color="blue-gray" className="mb-4 text-lg">
                Ønsker du å lære mer om hvordan vi kan hjelpe deg med å bygge din egen nettbutikk eller webapplikasjon? Ta kontakt med oss, så kan vi diskutere hvordan vi kan tilpasse en løsning som passer perfekt for dine behov.
              </Typography>
            </CardBody>
          </Card>
        </div>
      </section>

      <div className="bg-white">
        <Footer />
      </div>
    </>
  );
}

export default AboutUs;
