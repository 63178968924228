import { useState } from "react";
import { Typography, Button, Input } from "@material-tailwind/react";
import { Footer } from "@/widgets/layout";

export function OrderStatus() {
  const [orderNumber, setOrderNumber] = useState("");
  const [email, setEmail] = useState("");
  const [orderStatus, setOrderStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const searchOrderStatus = async () => {
    if (!orderNumber || !email) {
      setErrorMessage("Vennligst fyll ut både ordrenummer og e-post.");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `https://quackend.polarcode.solutions/orders/${orderNumber}/${encodeURIComponent(email)}/`
      );

      if (!response.ok) {
        throw new Error("Ordren ble ikke funnet.");
      }

      const data = await response.json();
      setOrderStatus(data);
      setErrorMessage("");
    } catch (error) {
      setOrderStatus(null);
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Bakgrunnsseksjon med bilde */}
      <section className="relative block h-[50vh] md:h-[70vh]">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('/img/search_duck.webp')] bg-cover bg-center md:bg-right bg-no-repeat" />
        <div className="absolute top-0 h-full w-full bg-black/60 z-0" />
      </section>

      {/* Søke etter ordrestatus */}
      <section className="relative bg-white py-16">
        <div className="container mx-auto max-w-md">
          <Typography variant="h3" color="blue-gray" className="text-center mb-4">
            Søk etter ordrestatus
          </Typography>

          <div className="mb-6">
            <Input
              label=""
              value={orderNumber}
              onChange={(e) => setOrderNumber(e.target.value)}
              className="mb-4"
              type="text"
              placeholder="Skriv inn ditt ordrenummer"
            />
            <Input
              label=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Skriv inn e-post brukt i bestilling"
            />
          </div>

          <Button className="w-full bg-gray-900" onClick={searchOrderStatus} disabled={loading}>
            {loading ? "Laster..." : "Søk"}
          </Button>

          {/* Vise feilmelding eller resultater */}
          {errorMessage && (
            <Typography color="red" className="mt-4 text-center">
              {errorMessage}
            </Typography>
          )}

          {orderStatus && (
            <div className="mt-8 p-4 bg-gray-100 rounded-lg shadow-md">
              <Typography variant="h5" color="blue-gray" className="mb-4">
                Ordrestatus for #{orderNumber}
              </Typography>
              <Typography color="blue-gray">
                <strong>Status:</strong> {orderStatus.status}
              </Typography>
              <Typography color="blue-gray">
                <strong>Dato:</strong> {new Date(orderStatus.order_date).toLocaleDateString()}
              </Typography>
              <div className="mt-4">
                <Typography color="blue-gray">
                  <strong>Produkter:</strong>
                </Typography>
                <ul className="list-disc list-inside">
                  {orderStatus.items.map((item) => (
                    <li key={item.product}>
                      Produkt-ID: {item.product}, Antall: {item.quantity}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </section>

      <div className="bg-white">
        <Footer />
      </div>
    </>
  );
}

export default OrderStatus;
