"use client";
import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
  Button,
  Input,
  Textarea,
} from "@material-tailwind/react";
import { PageTitle, Footer } from "@/widgets/layout";
import { FeatureCard } from "@/widgets/cards"; // Bruker FeatureCard-komponenten
import { Link } from 'react-router-dom';

export function Home() {
  const [featuresData, setFeaturesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [result, setResult] = useState("");

  // Henter produkter fra API-et
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch("https://quackend.polarcode.solutions/products/");
        const data = await response.json();

        // Mappe API-dataene til riktig format for kortene
        const formattedData = data.map((product) => ({
          color: "gray", // Farge kan være statisk eller dynamisk
          title: product.name,
          image: product.image_url,
          description: product.ad_text,
        }));

        setFeaturesData(formattedData);
        setLoading(false);
      } catch (error) {
        setError("Feil ved lasting av produkter");
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");

    const formData = new FormData(event.target);
    formData.append("access_key", "db61b187-6ffa-4936-82e0-a6adb3d43e2f");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Quack sendt!");
      event.target.reset(); // Reset form on successful submission
    } else {
      console.log("Ups, noe feil har oppstått...", data);
      setResult(data.message); // Display error message
    }
  };

  return (
    <>
      <div className="relative flex h-screen content-center items-center justify-center pt-16 pb-32">
        <div className="absolute top-0 h-full w-full bg-[url('/img/duck_header.webp')] bg-cover bg-center" />
        <div className="absolute top-0 h-full w-full bg-black/60 bg-cover bg-center" />
        <div className="max-w-8xl container relative mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="ml-auto mr-auto w-full px-4 text-center lg:w-8/12">
              <Typography
                variant="h1"
                color="white"
                className="mb-6 font-black"
              >
                QuackShop
              </Typography>
              <Typography variant="lead" color="white" className="opacity-80">
                3D-printede ender av miljøvennlig plastikk.
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <section className="-mt-32 bg-white px-4 pb-20 pt-4">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {loading ? (
              <Typography variant="lead" color="blue-gray">
                Laster produkter...
              </Typography>
            ) : error ? (
              <Typography variant="lead" color="red">
                {error}
              </Typography>
            ) : (
              featuresData.map(({ color, title, image, description }) => (
                <Link to="/products" key={title}>
                  <FeatureCard
                    color={color}
                    title={title}
                    description={description}
                    image={image}
                  />
                </Link>
              ))
            )}
          </div>

          <div className="mt-32 flex flex-wrap items-center">
            <div className="mx-auto -mt-8 w-full px-4 md:w-5/12">
              <div className="mb-6 inline-flex h-16 w-16 items-center justify-center rounded-full bg-blue-gray-900 p-2 text-center shadow-lg">
                <img src="/img/194633.png" alt="Duck" />
              </div>
              <Typography
                variant="h3"
                className="mb-3 font-bold"
                color="blue-gray"
              >
                Alle trenger en and i livet sitt
              </Typography>
              <Typography className="mb-8 font-normal text-blue-gray-500">
                Det er noe med den stille, rolige tilstedeværelsen til en and som gir hverdagen et snev av glede. 
                Enten du vil ha litt selskap på skrivebordet, en morsom dekorasjon i hjemmet, eller bare trenger et smil – en and er der for deg.
                <br />
                <br />
                De bringer ikke bare humor, men minner oss også på å ta livet litt mindre alvorlig. Med en and ved din side, har du alltid en venn som ikke dømmer!
              </Typography>
              <Link to="/aboutducks">
                <Button className="bg-yellow-500 mt-6" variant="filled">
                  Les mer
                </Button>
              </Link>
            </div>

            <div className="mx-auto mt-24 flex w-full justify-center px-4 md:w-4/12 lg:mt-0">
              <Card className="shadow-lg border shadow-gray-500/10 rounded-lg">
                <CardHeader floated={false} className="relative h-56">
                  <img
                    alt="Card Image"
                    src="/img/ChillWithDuck.webp"
                    className="h-full w-full object-cover object-center"
                  />
                </CardHeader>
                <CardBody>
                  <Typography variant="small" color="blue-gray" className="font-normal"></Typography>
                  <Typography
                    variant="h5"
                    color="blue-gray"
                    className="mb-3 mt-2 font-bold"
                  >
                    En venn for livet
                  </Typography>
                  <Typography className="font-normal text-blue-gray-500">
                    En liten kompis du kan stole på, prate med, le med, kjefte på og krangle med... som aldri dømmer deg, men heller hjelper deg å finne roen og resonere. Alltid ved din side, uansett hva.
                  </Typography>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </section>

      <section className="relative bg-white py-24 px-4">
        <div className="container mx-auto">
          <PageTitle section="Oppdag våre ender" heading="Din nye følgesvenn venter på deg">
            Fra den elegante Sir Quackalot til den kule QuackyMcFly – våre 3D-printede ender er mer enn bare pynt. De er små venner du kan stole på, enten du trenger litt selskap eller bare et smil i hverdagen. 
          </PageTitle>

          <div className="mx-auto mt-20 mb-48 grid max-w-5xl grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-3">
            {featuresData.map(({ title, image, description }) => (
              <Link to="/products" key={title}>
                <Card
                  color="transparent"
                  shadow={false}
                  className="text-center text-blue-gray-900"
                >
                  <div className="mx-auto mb-6 grid h-14 w-14 place-items-center rounded-full bg-yellow-300 shadow-lg shadow-gray-500/20">
                    <img src={image} alt={title} className="w-10 h-10" />
                  </div>
                  <Typography variant="h5" color="blue-gray" className="mb-2">
                    {title}
                  </Typography>
                  <Typography className="font-normal text-blue-gray-500">
                    {description}
                  </Typography>
                </Card>
              </Link>
            ))}
          </div>

          <div>
            <PageTitle section="Spør oss om våre ender" heading="Trenger du hjelp til å velge din nye venn?" />
            <form onSubmit={onSubmit} className="mx-auto w-full mt-12 lg:w-5/12 space-y-6">
              <Input 
                variant="outlined" 
                size="lg" 
                label="Fullt Navn" 
                name="name"
                required 
                className="w-full" 
              />
              
              <Input 
                variant="outlined" 
                size="lg" 
                label="E-postadresse" 
                name="email"
                type="email"
                required 
                className="w-full" 
              />

              <Textarea 
                variant="outlined" 
                size="lg" 
                label="Din Melding" 
                name="message"
                rows={8} 
                required
                className="w-full" 
              />

              <Button 
                variant="gradient" 
                size="lg" 
                type="submit" 
                className="mt-6 w-full"
              >
                Send Melding
              </Button>
              <span>{result}</span>
            </form>                
          </div>
        </div>
      </section>

      <div className="bg-white">
        <Footer />
      </div>
    </>
  );
}

export default Home;
