import { Avatar, Typography, Button, Input } from "@material-tailwind/react";
import {
  ShoppingCartIcon,
  TagIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";
import { Footer } from "@/widgets/layout";
import { useEffect, useState } from "react";

export function ProductShowcase() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [products, setProducts] = useState([]); 
  const [quantities, setQuantities] = useState({});
  const [showInfo, setShowInfo] = useState({}); 

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch("https://quackend.polarcode.solutions/products/");
        const data = await response.json();
        
        const formattedProducts = data.map(product => ({
          id: product.id,
          name: product.name,
          price: `${product.price} kr`,
          description: product.ad_text,
          image: product.image_url,
          stock: product.quantity_in_stock,
          weight: `${product.weight}g`,
          dimensions: `L,B,H: ${product.length}cm x ${product.width}cm x ${product.height}cm`,
        }));

        setProducts(formattedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const handleQuantityChange = (productId, value) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: Math.min(value, products.find((p) => p.id === productId).stock),
    }));
  };

  const handleAddToCart = (productId) => {
    const quantity = quantities[productId] || 1;
    const product = products.find((p) => p.id === productId);
  
    if (quantity <= product.stock) {
      const cart = JSON.parse(localStorage.getItem("cart")) || [];
      const existingProduct = cart.find((item) => item.id === productId);
      if (existingProduct) {
        existingProduct.quantity = Math.min(existingProduct.quantity + quantity, product.stock);
      } else {
        cart.push({ ...product, quantity });
      }
      localStorage.setItem("cart", JSON.stringify(cart));
      
      // Utløs en 'storage' hendelse for å oppdatere CartOverlay
      window.dispatchEvent(new Event("storage"));
  
      alert(`Du har lagt til ${quantity} stk. av ${product.name} i handlekurven.`);
    } else {
      alert(`Beklager, du kan ikke legge til mer enn ${product.stock} stk. av ${product.name}.`);
    }
  };
  

  const toggleInfo = (productId) => {
    setShowInfo((prevShowInfo) => ({
      ...prevShowInfo,
      [productId]: !prevShowInfo[productId],
    }));
  };

  return (
    <>
      <section className="relative block h-[50vh] md:h-[70vh]">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('/img/duckmaking_machine2.webp')] bg-cover bg-center md:bg-right bg-no-repeat" />
        <div className="absolute top-0 h-full w-full bg-black/60 z-0" />
      </section>

      <section className="relative bg-white py-16">
        <div className="container mx-auto">
          <Typography variant="h2" color="blue-gray" className="text-center mb-8">
            Vår pro'duck'tkolleksjon
          </Typography>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {products.map((product) => (
              <div
                key={product.id}
                className="relative flex flex-col items-center bg-white shadow-lg p-6"
              >
                <Avatar
                  src={product.image}
                  alt={product.name}
                  variant="rounded"
                  className="h-40 w-40 mb-4"
                />
                <Typography variant="h5" color="blue-gray" className="mb-2 text-center">
                  {product.name}
                </Typography>
                <Typography color="blue-gray" className="mb-4 text-center">
                  {product.description}
                </Typography>
                <div className="flex items-center gap-2">
                  <TagIcon className="h-5 w-5 text-blue-gray-500" />
                  <Typography color="blue-gray">{product.price}</Typography>
                </div>
                <Typography color="blue-gray" className="mt-2">
                  {`Lager: ${product.stock} stk.`}
                </Typography>
                <div className="mt-4">
                  <Input
                    type="number"
                    label="Antall"
                    value={quantities[product.id] || 1}
                    onChange={(e) => handleQuantityChange(product.id, parseInt(e.target.value))}
                    min="1"
                    max={product.stock}
                  />
                </div>
                <Button
                  className="mt-4 bg-gray-900 w-fit"
                  onClick={() => handleAddToCart(product.id)}
                >
                  <ShoppingCartIcon className="h-5 w-5 mr-2" />
                  Legg i Handlekurv
                </Button>

                <div className="mt-4">
                  <Button
                    className="bg-gray-200 w-full text-blue-gray-500"
                    onClick={() => toggleInfo(product.id)}
                  >
                    {showInfo[product.id] ? "Skjul info" : "Mer info"}
                    {showInfo[product.id] ? (
                      <ChevronUpIcon className="h-5 w-5 ml-2" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5 ml-2" />
                    )}
                  </Button>
                  {showInfo[product.id] && (
                    <div className="mt-2 p-4 bg-gray-100 rounded-lg shadow-inner w-full">
                      <Typography color="blue-gray">
                        <strong>Vekt:</strong> {product.weight}
                      </Typography>
                      <Typography color="blue-gray">
                        <strong>Dimensjoner:</strong> {product.dimensions}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="bg-white">
        <Footer />
      </div>
    </>
  );
}

export default ProductShowcase;
