import { useState, useEffect } from "react";
import { Avatar, Typography, Button } from "@material-tailwind/react";
import { TrashIcon } from "@heroicons/react/24/solid";
import { Footer } from "@/widgets/layout";
import { useNavigate } from 'react-router-dom'; // Bruk React Router for navigasjon

export function ShoppingCart() {
  console.log("Loading Shopping Cart Page");
  const [cart, setCart] = useState([]);
  const navigate = useNavigate(); // For å navigere til betalingsside

  // Hent handlekurv fra localStorage når komponenten laster
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(storedCart);
  }, []);

  // Funksjon for å fjerne produkt fra handlekurven
  const removeFromCart = (productId) => {
    const updatedCart = cart.filter((product) => product.id !== productId);
    setCart(updatedCart);
    
    // Oppdater localStorage etter fjerning
    localStorage.setItem("cart", JSON.stringify(updatedCart));

    // Utløs en 'storage' hendelse for å oppdatere CartOverlay
    window.dispatchEvent(new Event("storage"));

    // Oppdater siden
    window.location.reload();
  };


  // Beregn total pris og antall produkter i handlekurven
  const totalItems = cart.reduce((acc, item) => acc + item.quantity, 0);
  const totalPrice = cart.reduce((acc, item) => {
    const price = parseInt(item.price.replace(",-", ""), 10);
    return acc + price * item.quantity;
  }, 0);

  // Beregn total vekt
  const totalWeight = cart.reduce((acc, item) => {
    const weight = parseInt(item.weight.replace("g", ""), 10); // Konverter vekt fra "g" til tall
    return acc + weight * item.quantity;
  }, 0);

  // Beregn fraktkostnad basert på total vekt
  const shippingCost = totalWeight <= 350 ? 70 : totalWeight <= 1000 ? 120 : 150;

  // Funksjon for å navigere til betalingsside
  const handleCheckout = () => {
    navigate('/checkout'); // Naviger til betalingssiden
  };

  return (
    <>
      {/* Bakgrunnsseksjon */}
      <section className="relative block h-[50vh] md:h-[70vh]">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('/img/shopping_duck2.webp')] bg-cover bg-center md:bg-right bg-no-repeat" />
        <div className="absolute top-0 h-full w-full bg-black/60 z-0" />
      </section>


      {/* Handlekurvvisning */}
      <section className="relative bg-gray-100 py-8">
        <div className="container mx-auto">
          <Typography variant="h3" color="blue-gray" className="text-center mb-4">
            Handlekurv
          </Typography>
          {cart.length > 0 ? (
            <>
              <div className="flex flex-col items-center gap-6 mb-8">
                {cart.map((item) => (
                  <div
                    key={item.id}
                    className="w-full max-w-lg flex justify-between items-center bg-white shadow-md p-4"
                  >
                    <div className="flex items-center gap-4">
                      <Avatar
                        src={item.image}
                        alt={item.name}
                        variant="rounded"
                        className="h-16 w-16"
                      />
                      <div>
                        <Typography variant="h6" color="blue-gray">
                          {item.name}
                        </Typography>
                        <Typography color="blue-gray">
                          {item.price} x {item.quantity}
                        </Typography>
                      </div>
                    </div>
                    <Button
                      variant="text"
                      color="red"
                      onClick={() => removeFromCart(item.id)}
                    >
                      <TrashIcon className="h-5 w-5" />
                    </Button>
                  </div>
                ))}
              </div>
              <div className="text-center">
                <Typography variant="h5" color="blue-gray">
                  Totalt antall produkter: {totalItems}
                </Typography>
                <Typography variant="h5" color="blue-gray" className="mt-2">
                  Total pris: {totalPrice},-
                </Typography>
                <Typography variant="h5" color="blue-gray" className="mt-2">
                  Fraktkostnad: {shippingCost},-
                </Typography>
                <Typography variant="h5" color="blue-gray" className="mt-4">
                  Sum totalt: {totalPrice + shippingCost},-
                </Typography>
                {/* Gå til betaling-knapp */}
                <Button
                  className="mt-4 bg-blue-600 text-white"
                  onClick={handleCheckout}
                >
                  Gå til betaling
                </Button>
              </div>
            </>
          ) : (
            <Typography color="blue-gray" className="text-center">
              Handlekurven er tom.
            </Typography>
          )}
        </div>
      </section>

      <div className="bg-white">
        <Footer />
      </div>
    </>
  );
}

export default ShoppingCart;
