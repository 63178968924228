import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Navbar } from "@/widgets/layout";
import routes from "@/routes";
import CartOverlay from "./components/CartOverlayComp"; // Importer CartOverlay-komponenten

function App() {
  const { pathname } = useLocation();

  // Filtrer ut admin-rutene fra å vises i navbaren
  const visibleRoutes = routes.filter(
    (route) =>
      !route.path.startsWith("/admin") &&
      route.path !== "/handlekurv" &&
      route.path !== "/checkout" &&
      route.path !== "/aboutducks"
  );

  return (
    <>
      {/* Vis navbaren hvis vi ikke er på /sign-in eller /sign-up */}
      {!(pathname === "/sign-in" || pathname === "/sign-up") && (
        <div className="container absolute left-2/4 z-10 mx-auto -translate-x-2/4 p-4">
          <Navbar routes={visibleRoutes} />
        </div>
      )}
      
      {/* Legg til CartOverlay slik at den vises på alle sider */}
      <CartOverlay />
      
      <Routes>
        {routes.map(
          ({ path, element }, key) =>
            element && <Route key={key} exact path={path} element={element} />
        )}
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </>
  );
}

export default App;
