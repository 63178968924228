import React from "react";
import { Input, Button, Typography } from "@material-tailwind/react";
import { handleSaveProduct } from "../api/api";

const ProductForm = ({ newProduct, setNewProduct, editingProduct, setEditingProduct, setProducts }) => {
  return (
    <div className="mt-8">
      <Typography variant="h5" color="blue-gray" className="mb-4">
        {editingProduct ? "Rediger produkt" : "Legg til nytt produkt"}
      </Typography>

      {/* Vis ID-feltet kun når vi redigerer et produkt */}
      {editingProduct && (
        <Input
          label="Produkt ID"
          value={editingProduct.id} // Vis ID-en til produktet som redigeres
          disabled // Gjør feltet skrivebeskyttet, siden ID ikke skal endres
        />
      )}

      <Input
        label="Produktnavn"
        value={newProduct.name}
        onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
      />
      <Input
        label="Pris (NOK)"
        value={newProduct.price}
        onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
      />
      <Input
        label="Beskrivelse (Enkel beskrivelse av produktet)"
        value={newProduct.description}
        onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
      />
      <Input
        label="Annonsetekst (Vises under bildet på produktsiden)"
        value={newProduct.ad_text}
        onChange={(e) => setNewProduct({ ...newProduct, ad_text: e.target.value })}
      />
      <Input
        label="Lagerbeholdning (Antall på lager)"
        value={newProduct.quantity_in_stock}
        onChange={(e) => setNewProduct({ ...newProduct, quantity_in_stock: e.target.value })}
      />
      <Input
        label="Lengde (cm)"
        value={newProduct.length}
        onChange={(e) => setNewProduct({ ...newProduct, length: e.target.value })}
      />
      <Input
        label="Høyde (cm)"
        value={newProduct.height}
        onChange={(e) => setNewProduct({ ...newProduct, height: e.target.value })}
      />
      <Input
        label="Bredde (cm)"
        value={newProduct.width}
        onChange={(e) => setNewProduct({ ...newProduct, width: e.target.value })}
      />
      <Input
        label="Vekt (gram)"
        value={newProduct.weight}
        onChange={(e) => setNewProduct({ ...newProduct, weight: e.target.value })}
      />
      <input
        type="file"
        accept="image/*"
        onChange={(e) => setNewProduct({ ...newProduct, image: e.target.files[0] })}
      />
      <div className="flex gap-4 mt-4">
        <Button
          onClick={() => handleSaveProduct(newProduct, editingProduct, setProducts, setNewProduct)}
        >
          {editingProduct ? "Oppdater produkt" : "Legg til produkt"}
        </Button>

        {/* Legg til en Avbryt-knapp for å nullstille redigering */}
        {editingProduct && (
          <Button
            color="red"
            onClick={() => {
              setEditingProduct(null); // Nullstill redigeringsstatus
              setNewProduct({
                name: "",
                price: "",
                description: "",
                ad_text: "",
                quantity_in_stock: "",
                length: "",
                height: "",
                width: "",
                weight: "",
                image: null,
              }); // Nullstill feltene for nytt produkt
            }}
          >
            Avbryt
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProductForm;
