import React from "react";
import { Card, Avatar, Typography, Input, Button } from "@material-tailwind/react";
import { TrashIcon, PencilIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { removeProduct } from "../api/api";

const ProductList = ({ products, setProducts, editingProduct, setEditingProduct, setNewProduct, searchTerm, setSearchTerm }) => {
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="mb-8">
      <div className="flex justify-between mb-4">
        <Typography variant="h4" color="blue-gray">
          Administrer produkter
        </Typography>
        <Input
          label="Søk produkter"
          icon={<MagnifyingGlassIcon className="h-5 w-5" />}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        {filteredProducts.map((product) => (
          <Card key={product.id} className="p-4 flex justify-between items-start">
            <div className="flex items-start gap-4">
              <Avatar
                src={product.image_url}
                alt={product.name}
                variant="rounded"
                className="h-16 w-16"
              />
              <div>
                <Typography variant="h6" color="blue-gray">
                  {product.name} (ID: {product.id})
                </Typography>
                <Typography color="blue-gray">Pris: {product.price},- NOK</Typography>
                <Typography color="blue-gray">På lager: {product.quantity_in_stock}</Typography>
                <Typography color="blue-gray">Beskrivelse: {product.description}</Typography>
                <Typography color="blue-gray">Annonsetekst: {product.ad_text}</Typography>
                <Typography color="blue-gray">Lengde: {product.length} cm</Typography>
                <Typography color="blue-gray">Høyde: {product.height} cm</Typography>
                <Typography color="blue-gray">Bredde: {product.width} cm</Typography>
                <Typography color="blue-gray">Vekt: {product.weight} g</Typography>
              </div>
            </div>

            <div className="flex space-x-2 mt-4">
              <Button variant="text" color="blue" onClick={() => setEditingProduct(product)}>
                <PencilIcon className="h-5 w-5" />
              </Button>
              <Button
                variant="text"
                color="red"
                onClick={() => removeProduct(product.id, products, setProducts)}
              >
                <TrashIcon className="h-5 w-5" />
              </Button>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
