import React, { useState } from "react";
import { Typography, Button } from "@material-tailwind/react";
import OrderItem from "./OrderItem";
import OrderEditForm from "./OrderEditForm"; // Import OrderEditForm

const OrderList = ({ orders, products, setOrders }) => {
  const [editingOrder, setEditingOrder] = useState(null);

  const handleUpdateSuccess = (updatedOrder) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === updatedOrder.id ? updatedOrder : order
      )
    );
    setEditingOrder(null);
  };

  return (
    <div className="mb-8">
      <Typography variant="h4" color="blue-gray" className="mb-4">
        Bestillingsoversikt
      </Typography>
      {orders.map((order) => (
        <div key={order.id} className="mb-4">
          {editingOrder === order.id ? (
            <OrderEditForm
              order={order}
              onUpdateSuccess={handleUpdateSuccess}
              onCancel={() => setEditingOrder(null)}
            />
          ) : (
            <OrderItem
              order={order}
              products={products}
              setOrders={setOrders}
              onEdit={() => setEditingOrder(order.id)} // Legg til redigeringsfunksjon
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default OrderList;
