import React from "react";

// Direkte import av sidene
import Home from '@/pages/home';
import ProductShowcase from '@/pages/products';
import OrderStatus from '@/pages/orderSearch';
import ShoppingCart from '@/pages/shoppingcart';
import AdminDashboard from '@/pages/admin/dashboard';
import Login from '@/pages/admin/login';
import DuckArticle from "./pages/aboutducks";
import Checkout from "./pages/checkout";
import AboutUs from "./pages/aboutquackshop";
import Logout from "./pages/admin/logout";
import Statistics from "./pages/admin/statistics"; // Sørg for riktig importbane

export const routes = [
  {
    name: "Forside",
    path: "/home",
    element: <Home />,
  },
  {
    name: "Produkter",
    path: "/products",
    element: <ProductShowcase />,
  },
  {
    name: "Din bestilling",
    path: "/orderSearch",
    element: <OrderStatus />,
  },
  {
    name: "Om QuackShop",
    path: "/aboutquackshop",
    element: <AboutUs />,
  },
  {
    name: "Handlekurv",
    path: "/handlekurv",
    element: <ShoppingCart />,
  },
  {
    name: "checkout",
    path: "/checkout",
    element: <Checkout />,
  },
  {
    name: "Om endene",
    path: "/aboutducks",
    element: <DuckArticle />,
  },
  {
    name: "login",
    path: "/admin/login",
    element: <Login />,
  },
  {
    name: "logout",
    path: "/admin/logout",
    element: <Logout />,
  },
  {
    name: "dashboard",
    path: "/admin/dashboard",
    element: <AdminDashboard />,
  },
  {
    name: "statistics",
    path: "/admin/statistics",
    element: <Statistics />,
  },
];

export default routes;
