import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // for navigering etter vellykket innlogging
import { Card, Input, Button, Typography } from "@material-tailwind/react";
import { LockClosedIcon, UserIcon } from "@heroicons/react/24/solid";

export default function DuckLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loginAttempts, setLoginAttempts] = useState(0); // Ny tilstand for å spore innloggingsforsøk
  const [captchaSolved, setCaptchaSolved] = useState(false); // Tilstand for om CAPTCHA er løst
  const navigate = useNavigate(); // bruker useNavigate for navigering

  const handleLogin = async (e) => {
    e.preventDefault();

    if (loginAttempts % 2 === 1 && !captchaSolved) {
      setErrorMessage("Bekreft at du er menneske.");
      return;
    }

    const loginData = {
      username: email,
      password: password,
    };

    try {
      const response = await fetch("https://quackend.polarcode.solutions/api/token/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      if (!response.ok) {
        throw new Error("Feil e-post eller passord. Vennligst prøv igjen.");
      }

      const data = await response.json();
      // Lagre tokenet i lokal lagring
      localStorage.setItem("accessToken", data.access);
      localStorage.setItem("refreshToken", data.refresh);

      // Omdiriger til admin-dashbordet etter vellykket innlogging
      navigate("/admin/dashboard");
    } catch (error) {
      setErrorMessage(error.message);
      setLoginAttempts((prev) => prev + 1); // Øk antall forsøk ved feil
      setCaptchaSolved(false); // Tilbakestill CAPTCHA-status ved feil
    }
  };

  const handleCaptchaSolve = () => {
    setCaptchaSolved(true);
    setErrorMessage(""); // Fjern feilmelding hvis CAPTCHA løses
  };

  return (
    <div className="flex items-center justify-center h-screen bg-blue-100">
      <Card className="w-full max-w-md p-6 bg-white shadow-lg rounded-lg">
        <div className="flex justify-center mb-4">
          <img src="/img/favicon.png" alt="Duck Logo" className="h-24 w-24" />
        </div>
        <Typography variant="h4" color="blue-gray" className="text-center mb-6">
          Velkommen til DuckShop dashboard
        </Typography>
        <Typography color="blue-gray" className="text-center mb-4">
          Logg inn for å kvakke videre!
        </Typography>
        <form className="space-y-6" onSubmit={handleLogin}>
          <div>
            <Input
              type="email"
              label="E-post"
              icon={<UserIcon className="h-5 w-5 text-blue-500" />}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <Input
              type="password"
              label="Passord"
              icon={<LockClosedIcon className="h-5 w-5 text-blue-500" />}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          {/* Vis CAPTCHA hvert andre forsøk */}
          {loginAttempts % 2 === 1 && !captchaSolved && (
            <div className="text-center">
              <Typography variant="small" color="blue-gray" className="mb-2">
                Bekreft at du er menneske
              </Typography>
              <Button onClick={handleCaptchaSolve} color="green">
                Jeg er et menneske
              </Button>
            </div>
          )}

          <Button color="yellow" className="w-full mt-4" type="submit">
            Quack Inn
          </Button>
          {errorMessage && (
            <Typography color="red" className="text-center mt-4">
              {errorMessage}
            </Typography>
          )}
        </form>
      </Card>
    </div>
  );
}
