// src/components/OrderItem.js
import React, { useState } from "react";
import { Typography, Button, Select, Option } from "@material-tailwind/react";
import { updateOrderStatus, deleteOrder } from "../api/api";

const OrderItem = ({ order, products, setOrders, onEdit }) => {
  const [selectedStatus, setSelectedStatus] = useState(order.status); // Lokal tilstand for valgt status
  const [isUpdated, setIsUpdated] = useState(false); // Lokal tilstand for å spore om en ny status er valgt

  const handleStatusChange = (newStatus) => {
    setSelectedStatus(newStatus); // Oppdater lokal tilstand umiddelbart
    setIsUpdated(newStatus !== order.status); // Sett isUpdated til true hvis statusen er forskjellig fra den nåværende
  };

  const handleUpdateStatus = async () => {
    try {
      await updateOrderStatus(order.id, selectedStatus, setOrders); // Send API-forespørsel for å oppdatere statusen
      setIsUpdated(false); // Tilbakestill isUpdated etter at statusen er oppdatert
      window.location.reload(); // Oppdater siden
    } catch (error) {
      console.error("Error updating order status:", error); // Logg feilen
    }
  };

  return (
    <div className="bg-white shadow-md p-4 mb-4">
      <Typography variant="h6" color="blue-gray" className="mb-2">
        Ordre: {order.id}
      </Typography>
      <Typography variant="h6" color="blue-gray" className="mb-2">
        Kunde: {order.full_name}
      </Typography>
      <Typography variant="paragraph" color="blue-gray" className="mb-2">
        Adresse: {order.address}, {order.city}, {order.postal_code}, {order.country}
      </Typography>
      <Typography variant="paragraph" color="blue-gray" className="mb-2">
        E-post: {order.email}
      </Typography>
      <Typography variant="paragraph" color="blue-gray" className="mb-2">
        Dato: {new Date(order.order_date).toLocaleString()}
      </Typography>
      <Typography variant="paragraph" color="blue-gray" className="mb-4">
        Status: {selectedStatus}
      </Typography>

      <Typography variant="h6" color="blue-gray" className="mb-2">
        Produkter:
      </Typography>
      {order.items && Array.isArray(order.items) && order.items.map((item, index) => {
        const product = products.find((p) => p.id === item.product);
        return (
          <div key={index} className="mb-2">
            <Typography variant="paragraph" color="blue-gray">
              {item.quantity} x {product?.name || "Ukjent produkt"} - {product?.price || 0} NOK per enhet
            </Typography>
          </div>
        );
      })}

      <Typography variant="paragraph" color="blue-gray" className="mb-4">
        Total:{" "}
        {order.items && Array.isArray(order.items) && order.items.reduce((total, item) => {
          const productPrice = products.find((p) => p.id === item.product)?.price || 0;
          const productQuantity = parseInt(item.quantity, 10);
          return total + productPrice * productQuantity;
        }, 0)}{" "}
        NOK
      </Typography>

      <div className="mb-4">
        <Select
          label="Oppdater status"
          value={selectedStatus}
          onChange={(value) => handleStatusChange(value)}
        >
          <Option key="venter" value="Venter behandling">Venter behandling</Option>
          <Option key="sendt" value="Sendt">Sendt</Option>
          <Option key="kansellert" value="Kansellert">Kansellert</Option>
        </Select>
      </div>

      {isUpdated && (
        <div className="mt-4">
          <Button variant="filled" color="green" onClick={handleUpdateStatus}>
            Oppdater
          </Button>
        </div>
      )}
      
      <div className="flex justify-end mt-4 gap-4">
        <Button variant="text" color="blue" onClick={onEdit}>
          Rediger
        </Button>
        
        <Button variant="text" color="red" onClick={() => deleteOrder(order.id, order.email, setOrders)}>
          Slett bestilling
        </Button>
      </div>
    </div>
  );
};

export default OrderItem;
