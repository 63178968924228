// src/components/CartOverlay.js
import React, { useState, useEffect } from "react";
import { ShoppingCartIcon } from "@heroicons/react/24/solid"; // Korrigert import for Heroicons v2
import { useNavigate } from "react-router-dom";

const CartOverlay = () => {
  const [cartCount, setCartCount] = useState(0);
  const navigate = useNavigate(); // Bruk useNavigate for å navigere til handlekurven

  useEffect(() => {
    // Funksjon for å hente antall varer i handlekurven
    const updateCartCount = () => {
      const cart = JSON.parse(localStorage.getItem("cart")) || [];
      const totalCount = cart.reduce((count, item) => count + item.quantity, 0);
      setCartCount(totalCount);
    };

    // Kall funksjonen når komponenten mountes
    updateCartCount();

    // Oppdater antall varer i handlekurven når `storage` endres
    const handleStorageChange = () => {
      updateCartCount();
    };

    window.addEventListener("storage", handleStorageChange);

    // Fjern event listener når komponenten unmountes
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // Hvis handlekurven er tom, ikke vis noe
  if (cartCount === 0) {
    return null;
  }

  return (
    <div
      className="fixed bottom-4 right-4 bg-red-600 text-white rounded-full w-12 h-12 flex items-center justify-center cursor-pointer"
      onClick={() => navigate("/handlekurv")} // Naviger til handlekurv ved klikk
      title="Gå til handlekurv"
      style={{ zIndex: 9999 }} // Sørg for at denne komponenten alltid ligger øverst
    >
      <div className="relative">
        <ShoppingCartIcon className="w-6 h-6" />
        {/* Vis antall varer nederst til venstre for handlekurv-ikonet */}

      </div>
      <span className="absolute bottom-0 left-0 bg-white text-red-600 rounded-full w-5 h-5 flex items-center justify-center text-xs font-bold">
          {cartCount}
        </span>
    </div>
  );
};

export default CartOverlay;
