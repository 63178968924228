// src/components/StatsCard.js
import React from "react";
import { Card, Typography } from "@material-tailwind/react";
import { ChartBarIcon } from "@heroicons/react/24/solid";

const StatsCard = ({ title, value }) => {
  return (
    <Card className="p-4">
      <Typography variant="h6" color="blue-gray" className="mb-2">
        {title}
      </Typography>
      <ChartBarIcon className="h-12 w-12 text-blue-gray-500" />
      <Typography variant="h4" color="blue-gray">
        {value}
      </Typography>
    </Card>
  );
};

export default StatsCard;
