import React, { useState } from "react";
import { Input, Button, Typography, Select, Option } from "@material-tailwind/react";
import { updateOrder } from "../api/api.jsx";

const OrderEditForm = ({ order, onUpdateSuccess, onCancel }) => {
  const [formData, setFormData] = useState({
    full_name: order.full_name,
    address: order.address,
    city: order.city,
    postal_code: order.postal_code,
    country: order.country,
    status: order.status,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleStatusChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      status: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const updatedOrder = await updateOrder(order.id, formData);
      onUpdateSuccess(updatedOrder);
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  return (
    <div className="p-4 border rounded bg-gray-100">
      <Typography variant="h5" color="blue-gray" className="mb-4">
        Rediger ordre (ID: {order.id})
      </Typography>
      <Input
        label="Fullt navn"
        name="full_name"
        value={formData.full_name}
        onChange={handleInputChange}
        className="mb-4"
      />
      <Input
        label="Adresse"
        name="address"
        value={formData.address}
        onChange={handleInputChange}
        className="mb-4"
      />
      <Input
        label="By"
        name="city"
        value={formData.city}
        onChange={handleInputChange}
        className="mb-4"
      />
      <Input
        label="Postnummer"
        name="postal_code"
        value={formData.postal_code}
        onChange={handleInputChange}
        className="mb-4"
      />
      <Input
        label="Land"
        name="country"
        value={formData.country}
        onChange={handleInputChange}
        className="mb-4"
      />
      
      {/* Status dropdown */}
      <Select
        label="Status"
        value={formData.status}
        onChange={(e) => handleStatusChange(e)}
        className="mb-4"
      >
        <Option value="Venter behandling">Venter behandling</Option>
        <Option value="Sendt">Sendt</Option>
        <Option value="Kansellert">Kansellert</Option>
      </Select>

      <div className="flex gap-4">
        <Button onClick={handleSubmit}>Lagre endringer</Button>
        <Button color="red" onClick={onCancel}>
          Avbryt
        </Button>
      </div>
    </div>
  );
};

export default OrderEditForm;
