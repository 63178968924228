// root/src/pages/admin/Statistics.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Select, Option } from "@material-tailwind/react";
import { Footer } from "@/widgets/layout";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { checkTokenValidity, fetchOrders, fetchProducts } from "../../api/api.jsx";

export function Statistics() {
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [startDate, setStartDate] = useState(null); // Start date for filtering
  const [endDate, setEndDate] = useState(null); // End date for filtering
  const [selectedProduct, setSelectedProduct] = useState("all"); // Default value for all products
  const [selectedStatus, setSelectedStatus] = useState("all"); // Default value for all statuses
  const [orderStartDate, setOrderStartDate] = useState(null); // Start date for order status filtering
  const [orderEndDate, setOrderEndDate] = useState(null); // End date for order status filtering
  const [filteredSalesData, setFilteredSalesData] = useState([]);
  const [mostSoldProducts, setMostSoldProducts] = useState([]);
  const [orderStats, setOrderStats] = useState([]); // Data for order status chart

  const navigate = useNavigate();

  useEffect(() => {
    const initializeDashboard = async () => {
      const isTokenValid = await checkTokenValidity(navigate);
      if (!isTokenValid) return;

      const fetchedProducts = await fetchProducts();
      const fetchedOrders = await fetchOrders();
      setProducts(fetchedProducts);
      setOrders(fetchedOrders);

      filterData(fetchedOrders, fetchedProducts, startDate, endDate, selectedProduct);
      filterOrderStatusData(fetchedOrders, orderStartDate, orderEndDate);
    };

    initializeDashboard();
  }, [navigate, startDate, endDate, selectedProduct, selectedStatus, orderStartDate, orderEndDate]);

  const filterData = (orders, products, startDate, endDate, product) => {
    const filteredOrders = orders.filter((order) => {
      const orderDate = new Date(order.order_date);
      const isDateInRange = (!startDate || orderDate >= startDate) && (!endDate || orderDate <= endDate);
      const isProductMatch = product === "all" || order.items.some((item) => item.product === product);
      return isDateInRange && isProductMatch;
    });

    prepareSalesDataForChart(filteredOrders, products);
    calculateMostSoldProducts(filteredOrders, products);
  };

  const filterOrderStatusData = (orders, startDate, endDate) => {
    const filteredOrders = orders.filter((order) => {
      const orderDate = new Date(order.order_date);
      return (!startDate || orderDate >= startDate) && (!endDate || orderDate <= endDate);
    });

    prepareOrderStats(filteredOrders);
  };

  const prepareSalesDataForChart = (orders, products) => {
    const productPriceMap = products.reduce((acc, product) => {
      acc[product.id] = parseFloat(product.price);
      return acc;
    }, {});

    const data = orders.map((order) => {
      const total = order.items.reduce((acc, item) => {
        const productPrice = productPriceMap[item.product] || 0;
        const productQuantity = parseInt(item.quantity, 10);
        return acc + productPrice * productQuantity;
      }, 0);

      return {
        date: new Date(order.order_date).toLocaleDateString(),
        total: total,
      };
    });

    setFilteredSalesData(data);
  };

  const calculateMostSoldProducts = (orders, products) => {
    const productQuantityMap = {};

    orders.forEach((order) => {
      order.items.forEach((item) => {
        productQuantityMap[item.product] = (productQuantityMap[item.product] || 0) + item.quantity;
      });
    });

    const mostSold = products
      .map((product) => ({
        name: product.name,
        quantity: productQuantityMap[product.id] || 0,
      }))
      .sort((a, b) => b.quantity - a.quantity);

    setMostSoldProducts(mostSold);
  };

  const prepareOrderStats = (orders) => {
    const statusCount = {
      "Venter behandling": 0,
      Sendt: 0,
      Kansellert: 0,
    };

    orders.forEach((order) => {
      statusCount[order.status] = (statusCount[order.status] || 0) + 1;
    });

    const orderStatsData = Object.keys(statusCount).map((status) => ({
      status,
      count: statusCount[status],
    }));

    setOrderStats(orderStatsData);
  };

  return (
    <>
      <section className="relative block h-[50vh] md:h-[70vh]">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('/img/stor_andministrator.webp')] bg-cover bg-center md:bg-right bg-no-repeat" />
        <div className="absolute top-0 h-full w-full bg-black/60 z-0" />
      </section>

      <section className="relative bg-gray-100 py-8">
        <div className="container mx-auto">
          <Typography variant="h3" color="blue-gray" className="text-center mb-4">
            Statistikkside for salg og produkter
          </Typography>

          {/* Filtreringsalternativer */}
          <div className="flex justify-center mb-8 gap-4">
            <div>
              <Typography variant="h6" color="blue-gray">Startdato</Typography>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd/MM/yyyy"
                className="border p-2 rounded"
                placeholderText="Velg startdato"
              />
            </div>
            <div>
              <Typography variant="h6" color="blue-gray">Sluttdato</Typography>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="dd/MM/yyyy"
                className="border p-2 rounded"
                placeholderText="Velg sluttdato"
              />
            </div>
            <Select label="Velg produkt" value={selectedProduct} onChange={(value) => setSelectedProduct(value)}>
              <Option value="all">Alle produkter</Option>
              {products.map((product) => (
                <Option key={product.id} value={product.id}>{product.name}</Option>
              ))}
            </Select>
          </div>

          {/* Diagram for total salg */}
          <div className="mb-8">
            <Typography variant="h5" color="blue-gray" className="text-center mb-4">
              Salg over tid
            </Typography>
            <div style={{ width: "100%", height: 400 }}>
              <ResponsiveContainer>
                <LineChart data={filteredSalesData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="total" stroke="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Diagram for mest solgte produkter */}
          <div className="mb-8">
            <Typography variant="h5" color="blue-gray" className="text-center mb-4">
              Mest solgte produkter
            </Typography>
            <div style={{ width: "100%", height: 400 }}>
              <ResponsiveContainer>
                <BarChart data={mostSoldProducts} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="quantity" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Filtreringsalternativer for ordrestatistikk */}
          <div className="flex justify-center mb-8 gap-4">
            <div>
              <Typography variant="h6" color="blue-gray">Startdato for ordre</Typography>
              <DatePicker
                selected={orderStartDate}
                onChange={(date) => setOrderStartDate(date)}
                dateFormat="dd/MM/yyyy"
                className="border p-2 rounded"
                placeholderText="Velg startdato"
              />
            </div>
            <div>
              <Typography variant="h6" color="blue-gray">Sluttdato for ordre</Typography>
              <DatePicker
                selected={orderEndDate}
                onChange={(date) => setOrderEndDate(date)}
                dateFormat="dd/MM/yyyy"
                className="border p-2 rounded"
                placeholderText="Velg sluttdato"
              />
            </div>
          </div>

          {/* Diagram for ordrestatistikker */}
          <div className="mb-8">
            <Typography variant="h5" color="blue-gray" className="text-center mb-4">
              Ordrestatistikker etter status
            </Typography>
            <div style={{ width: "100%", height: 400 }}>
              <ResponsiveContainer>
                <BarChart data={orderStats} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="status" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="count" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-white">
        <Footer />
      </div>
    </>
  );
}

export default Statistics;
