import PropTypes from "prop-types";
import { Typography, IconButton } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

const year = new Date().getFullYear();

export function Footer({ title, description, socials, menus, copyright }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Tilstand for innloggingsstatus

  useEffect(() => {
    // Sjekk om accessToken finnes i localStorage for å fastslå innloggingsstatus
    const token = localStorage.getItem("accessToken");
    setIsLoggedIn(!!token); // Sett true hvis token finnes, false ellers
  }, []); // Kjør kun én gang ved komponentens første lasting

  return (
    <footer className="relative px-4 pt-8 pb-6">
      <div className="container mx-auto">
        <div className="flex flex-wrap pt-6 text-center lg:text-left">
          <div className="w-full px-4 lg:w-6/12">
            <Typography variant="h4" className="mb-4" color="blue-gray">
              {title}
            </Typography>
            <Typography className="font-normal text-blue-gray-500 lg:w-2/5">
              {description}
            </Typography>
            <div className="mx-auto mt-6 mb-8 flex justify-center gap-2 md:mb-0 lg:justify-start">
              {socials.map(({ color, name, path }) => (
                <a
                  key={name}
                  href={path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    color="white"
                    className="rounded-full shadow-none bg-transparent"
                  >
                    <Typography color={color}>
                      <i className={`fa-brands fa-${name}`} />
                    </Typography>
                  </IconButton>
                </a>
              ))}
            </div>
          </div>

          {/* Sekjoner i grid med 3 kolonner for Butikk admin, Lenker, Om PolarCode */}
          <div className="mx-auto mt-12 grid w-max grid-cols-3 gap-12 lg:mt-0">
            {/* Butikk admin seksjon */}
            <div>
              <Typography
                variant="small"
                color="blue-gray"
                className="mb-2 block font-medium uppercase"
              >
                Butikk admin
              </Typography>
              <ul className="mt-3">
                {isLoggedIn ? (
                  <>
                    {/* Hvis innlogget, vis Logout og Dashboard */}
                    <li>
                      <Link to="/admin/logout">
                        <Typography
                          as="span"
                          variant="small"
                          className="mb-2 block font-normal text-blue-gray-500 hover:text-blue-gray-700"
                        >
                          Logout
                        </Typography>
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/dashboard">
                        <Typography
                          as="span"
                          variant="small"
                          className="mb-2 block font-normal text-blue-gray-500 hover:text-blue-gray-700"
                        >
                          Dashboard
                        </Typography>
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/statistics">
                        <Typography
                          as="span"
                          variant="small"
                          className="mb-2 block font-normal text-blue-gray-500 hover:text-blue-gray-700"
                        >
                          Statistikk
                        </Typography>
                      </Link>
                    </li>
                  </>
                ) : (
                  // Hvis ikke innlogget, vis Login
                  <li>
                    <Link to="/admin/login">
                      <Typography
                        as="span"
                        variant="small"
                        className="mb-2 block font-normal text-blue-gray-500 hover:text-blue-gray-700"
                      >
                        Login
                      </Typography>
                    </Link>
                  </li>
                )}
              </ul>
            </div>

            {/* Legger til Lenker-seksjonen */}
            <div>
              <Typography
                variant="small"
                color="blue-gray"
                className="mb-2 block font-medium uppercase"
              >
                Lenker
              </Typography>
              <ul className="mt-3">
                {menus[0].items.map((item) => (
                  <li key={item.name}>
                    <Typography
                      as="a"
                      href={item.path}
                      target="_blank"
                      rel="noreferrer"
                      variant="small"
                      className="mb-2 block font-normal text-blue-gray-500 hover:text-blue-gray-700"
                    >
                      {item.name}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>

            {/* Legger til Om PolarCode-seksjonen */}
            <div>
              <Typography
                variant="small"
                color="blue-gray"
                className="mb-2 block font-medium uppercase"
              >
                Om PolarCode
              </Typography>
              <ul className="mt-3">
                {menus[1].items.map((item) => (
                  <li key={item.name}>
                    <Typography
                      as="a"
                      href={item.path}
                      target="_blank"
                      rel="noreferrer"
                      variant="small"
                      className="mb-2 block font-normal text-blue-gray-500 hover:text-blue-gray-700"
                    >
                      {item.name}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <hr className="my-6 border-gray-300" />
        <div className="flex flex-wrap items-center justify-center md:justify-between">
          <div className="mx-auto w-full px-4 text-center">
            <Typography
              variant="small"
              className="font-normal text-blue-gray-500"
            >
              {copyright}
            </Typography>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.defaultProps = {
  title: "QuackShop",
  description:
    "Utviklet, driftet og hostet av PolarCodeSolutions. Vi setter opp din nettbutikk og hjelper med å komme i gang med salg på nett.",
  socials: [
    {
      color: "gray",
      name: "facebook",
      path: "https://www.facebook.com/profile.php?id=61560407736033",
    },
    {
      color: "gray",
      name: "instagram",
      path: "https://www.instagram.com/polarcodesolutions/",
    },
    {
      color: "gray",
      name: "discord",
      path: "https://discord.gg/hUAksuANZ4",
    },
  ],
  menus: [
    {
      name: "Lenker",
      items: [
        { name: "Om oss", path: "https://polarcode.solutions/om" },
        {
          name: "Github",
          path: "https://github.com/PolarCodeSolutions",
        },
      ],
    },
    {
      name: "Om PolarCode",
      items: [
        {
          name: "Tjenester",
          path: "https://polarcode.solutions/tjenester",
        },
        {
          name: "Design",
          path: "https://polarcode.solutions/eksempler",
        },
        {
          name: "Kontakt oss",
          path: "https://polarcode.solutions/kontakt",
        },
      ],
    },
  ],
  copyright: (
    <>
      Copyright © {year} QuackShop av{" "}
      <a
        href="https://www.creative-tim.com?ref=mtk"
        target="_blank"
        className="text-blue-gray-500 transition-colors hover:text-blue-500"
      >
        PolarCodeSolutions
      </a>
      .
    </>
  ),
};

Footer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  socials: PropTypes.arrayOf(PropTypes.object),
  menus: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.node,
};

Footer.displayName = "/src/widgets/layout/footer.jsx";

export default Footer;
