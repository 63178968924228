// root/src/pages/admin/AdminDashboard.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@material-tailwind/react";
import { Footer } from "@/widgets/layout"; // Sørg for at denne importen er riktig basert på prosjektets faktiske filplassering
import ProductList from "../../components/ProductList";
import ProductForm from "../../components/ProductForm";
import OrderList from "../../components/OrderList";
import StatsCard from "../../components/StatsCard";

import { checkTokenValidity, fetchProducts, fetchOrders } from "../../api/api.jsx";

export function AdminDashboard() {
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [newProduct, setNewProduct] = useState({
    name: "",
    price: "",
    description: "",
    ad_text: "",
    quantity_in_stock: "",
    length: "",
    height: "",
    width: "",
    weight: "",
    image: null,
  });
  const [editingProduct, setEditingProduct] = useState(null);
  const [totalSales, setTotalSales] = useState(0);
  const [totalStockValue, setTotalStockValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const initializeDashboard = async () => {
      const isTokenValid = await checkTokenValidity(navigate);
      if (!isTokenValid) return;

      const fetchedProducts = await fetchProducts();
      const fetchedOrders = await fetchOrders();
      setProducts(fetchedProducts);
      setOrders(fetchedOrders);

      calculateStockValue(fetchedProducts);
      calculateTotalSales(fetchedOrders, fetchedProducts);
    };

    initializeDashboard();
  }, [navigate]);

  const calculateStockValue = (products) => {
    const stockValue = products.reduce(
      (acc, product) => acc + parseFloat(product.price) * parseInt(product.quantity_in_stock, 10),
      0
    );
    setTotalStockValue(stockValue);
  };

  const calculateTotalSales = (orders, products) => {
    const productPriceMap = products.reduce((acc, product) => {
      acc[product.id] = parseFloat(product.price);
      return acc;
    }, {});

    const sales = orders.reduce((acc, order) => {
      const orderTotal = order.items.reduce((total, item) => {
        const productPrice = productPriceMap[item.product] || 0;
        const productQuantity = parseInt(item.quantity, 10);
        return total + productPrice * productQuantity;
      }, 0);
      return acc + orderTotal;
    }, 0);
    setTotalSales(sales);
  };

  return (
    <>
      <section className="relative block h-[50vh] md:h-[70vh]">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('/img/stor_andministrator.webp')] bg-cover bg-center md:bg-right bg-no-repeat" />
        <div className="absolute top-0 h-full w-full bg-black/60 z-0" />
      </section>

      <section className="relative bg-gray-100 py-8">
        <div className="container mx-auto">
          <Typography variant="h3" color="blue-gray" className="text-center mb-4">
            Admin Dashboard
          </Typography>

          {/* Statistikk */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <StatsCard title="Totalt salg" value={`${totalSales},- NOK`} />
            <StatsCard title="Totalt lagerverdi" value={`${totalStockValue},- NOK`} />
          </div>

          {/* Produktadministrasjon */}
          <ProductList
            products={products}
            setProducts={setProducts}
            editingProduct={editingProduct}
            setEditingProduct={setEditingProduct}
            setNewProduct={setNewProduct}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />

          {/* Skjema for å legge til/redigere produkt */}
          <ProductForm
            newProduct={newProduct}
            setNewProduct={setNewProduct}
            editingProduct={editingProduct}
            setEditingProduct={setEditingProduct}
            setProducts={setProducts}
          />

          {/* Bestillingsoversikt */}
          <OrderList orders={orders} products={products} setOrders={setOrders} />
        </div>
      </section>

      <div className="bg-white">
        <Footer />
      </div>
    </>
  );
}

export default AdminDashboard;
