// src/api/api.js

const API_BASE_URL = "https://quackend.polarcode.solutions"; // Definer API URL som en variabel

export const checkTokenValidity = async (navigate) => {
  const refreshToken = localStorage.getItem("refreshToken");

  if (!refreshToken) {
    navigate("/admin/login");
    return false;
  }

  try {
    const response = await fetch(`${API_BASE_URL}/api/token/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem("accessToken", data.access);
      return true;
    } else {
      navigate("/admin/login");
      return false;
    }
  } catch (error) {
    console.error("Error validating token:", error);
    navigate("/admin/login");
    return false;
  }
};

export const fetchProducts = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/products/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

export const fetchOrders = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/orders/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  } catch (error) {
    console.error("Error fetching orders:", error);
    return [];
  }
};

export const removeProduct = async (productId, products, setProducts) => {
  const confirmed = window.confirm("Er du sikker på at du vil slette dette produktet?");
  if (!confirmed) return;

  try {
    const response = await fetch(`${API_BASE_URL}/products/${productId}/`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    if (response.ok) {
      setProducts(products.filter((product) => product.id !== productId));
    } else {
      console.error("Failed to delete product");
    }
  } catch (error) {
    console.error("Error deleting product:", error);
  }
};

export const handleSaveProduct = async (newProduct, editingProduct, setProducts, setNewProduct) => {
  const url = editingProduct
      ? `${API_BASE_URL}/products/${editingProduct.id}/`
      : `${API_BASE_URL}/products/`;
  const method = editingProduct ? "PATCH" : "POST";

  // Create a FormData object
  const formData = new FormData();
  formData.append("name", newProduct.name);
  formData.append("description", newProduct.description);
  formData.append("ad_text", newProduct.ad_text);
  formData.append("price", parseFloat(newProduct.price).toFixed(2));
  formData.append("quantity_in_stock", newProduct.quantity_in_stock);
  formData.append("length", parseFloat(newProduct.length).toFixed(2));
  formData.append("height", parseFloat(newProduct.height).toFixed(2));
  formData.append("width", parseFloat(newProduct.width).toFixed(2));
  formData.append("weight", parseFloat(newProduct.weight).toFixed(2));
  
  // Append image if it exists
  if (newProduct.image) {
      formData.append("image", newProduct.image);
  }

  try {
      const response = await fetch(url, {
          method,
          headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: formData,
      });

      if (response.ok) {
          const updatedProduct = await response.json();
          setProducts((prevProducts) =>
              editingProduct
                  ? prevProducts.map((product) => (product.id === editingProduct.id ? updatedProduct : product))
                  : [...prevProducts, updatedProduct]
          );

          setNewProduct({
              name: "",
              price: "",
              description: "",
              ad_text: "",
              quantity_in_stock: "",
              length: "",
              height: "",
              width: "",
              weight: "",
              image: null,
          });
      } else {
          const errorData = await response.text(); // Log error details for debugging
          console.error("Failed to save product:", errorData);
      }
  } catch (error) {
      console.error("Error saving product:", error);
  }
};


export const updateOrderStatus = async (orderId, newStatus, setOrders) => {
  try {
    const response = await fetch(`${API_BASE_URL}/orders/${orderId}/`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status: newStatus }),
    });

    if (response.ok) {
      const updatedOrder = await response.json();
      setOrders((prevOrders) =>
        prevOrders.map((order) => (order.id === orderId ? updatedOrder : order))
      );
    } else {
      console.error("Failed to update order status");
    }
  } catch (error) {
    console.error("Error updating order status:", error);
  }
};

export const deleteOrder = async (orderId, email, setOrders) => {
  const confirmed = window.confirm("Er du sikker på at du vil slette denne bestillingen?");
  if (!confirmed) return;

  try {
    const response = await fetch(`${API_BASE_URL}/orders/${orderId}/${email}/`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      setOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderId));
    } else {
      console.error("Failed to delete order");
    }
  } catch (error) {
    console.error("Error deleting order:", error);
  }
};


export const updateOrder = async (orderId, updatedData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/orders/${orderId}/`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
      throw new Error("Failed to update the order.");
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating order:", error);
    throw error;
  }
};
