import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button } from "@material-tailwind/react";

export function DuckLogout() {
  const navigate = useNavigate();

  useEffect(() => {
    // Fjerner tokens fra localStorage
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    // Omdirigerer til innloggingssiden etter logout
    navigate("/admin/login", { replace: true });
  }, [navigate]);

  return (
    <section className="m-8 flex flex-col items-center justify-center h-screen bg-blue-100">
      <div className="text-center bg-white p-8 rounded-lg shadow-lg">
        <img src="/img/favicon.png" alt="Duck Logo" className="h-24 w-24 mx-auto mb-6" />
        <Typography variant="h2" className="font-bold mb-4">Logget ut</Typography>
        <Typography variant="paragraph" color="blue-gray" className="text-lg font-normal mb-6">
          Quack ut! Du har nå logget ut av DuckShop.
        </Typography>
        <Button
          color="yellow"
          className="w-full"
          onClick={() => navigate("/admin/login")}
        >
          Logg inn igjen
        </Button>
      </div>
    </section>
  );
}

export default DuckLogout;
