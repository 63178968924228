import { useEffect, useState } from "react";
import { Typography, Button, Input } from "@material-tailwind/react";
import { Footer } from "@/widgets/layout";

export function Checkout() {
  const [errorMessage, setErrorMessage] = useState("");
  const [productIds, setProductIds] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({
    fullName: "",
    address: "",
    city: "",
    postalCode: "",
    country: "Norge",
    email: ""
  });

  // Hente produkt-ID-er fra localStorage ved lasting av komponenten
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    const productIds = storedCart.map(item => item.id);
    setProductIds(productIds);
  }, []);

  // Funksjon for å oppdatere kundens informasjon
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  // Funksjon for å sende produkt-ID og kundeinformasjon til API (når API er klart)
  const sendProductInfoToApi = async () => {
    try {
      const response = await fetch("/api/checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ productIds, customerInfo }),
      });

      if (!response.ok) {
        throw new Error("Kunne ikke sende informasjonen.");
      }

      const data = await response.json();
      console.log("Data mottatt fra API:", data);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  // Simulere integrasjon med SumUp's Checkout-widget
  const handlePayment = () => {
    console.log("Initializing SumUp Checkout widget...");
    sendProductInfoToApi();
  };

  return (
    <>
      {/* Bakgrunnsseksjon med bilde */}
      <section className="relative block h-[50vh] md:h-[70vh]">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('/img/payment_duck.webp')] bg-cover bg-center md:bg-right bg-no-repeat" />
        <div className="absolute top-0 h-full w-full bg-black/60 z-0" />
      </section>

      {/* Betalingsseksjon med SumUp widget */}
      <section className="relative bg-white py-16 z-10">
        <div className="container mx-auto max-w-md">
          <Typography variant="h3" color="blue-gray" className="text-center mb-4">
            Fullfør din betaling
          </Typography>

          {/* Kontakt- og leveringsinformasjon */}
          <div className="mb-6">
            <Input
              label="Fullt navn"
              name="fullName"
              value={customerInfo.fullName}
              onChange={handleInputChange}
              className="mb-4"
              type="text"
              required
            />
            <Input
              label="Adresse"
              name="address"
              value={customerInfo.address}
              onChange={handleInputChange}
              className="mb-4"
              type="text"
              required
            />
            <Input
              label="By"
              name="city"
              value={customerInfo.city}
              onChange={handleInputChange}
              className="mb-4"
              type="text"
              required
            />
            <Input
              label="Postnummer"
              name="postalCode"
              value={customerInfo.postalCode}
              onChange={handleInputChange}
              className="mb-4"
              type="text"
              required
            />
            <Input
              label="E-post"
              name="email"
              value={customerInfo.email}
              onChange={handleInputChange}
              className="mb-4"
              type="email"
              required
            />
            <Input
              label="Land"
              name="country"
              value="Norge"
              disabled
              className="mb-4"
              type="text"
            />
          </div>

          <Button className="w-full bg-blue-600 text-white mb-4" onClick={handlePayment}>
            Bekreft og betal
          </Button>

          {errorMessage && (
            <Typography color="red" className="mt-4 text-center">
              {errorMessage}
            </Typography>
          )}

          {/* SumUp widget */}
          <div id="sumup-checkout-widget" className="mt-8 p-4 bg-gray-100 rounded-lg shadow-md">
            <Typography color="blue-gray" className="text-center">
              Checkout vil vises her når ordren er klar.
            </Typography>
          </div>
        </div>
      </section>

      <div className="bg-white">
        <Footer />
      </div>
    </>
  );
}

export default Checkout;
